<template>
    <div>
        <modal-link
            route="websites.points-of-interest"
            :params="{ id: $route.params.websiteId }"
        >
            <div class="helper-block bg-highlight-200 text-highlight-base border border-highlight-300 py-5 px-8 text-center text-sm font-frank font-500">
                Points of interest can be managed here
            </div>
        </modal-link>
        <div class="form-row mt-3" v-if="!pointsOfInterest.length">
            <div class="bg-red-100 p-4 text-xs w-full text-red-500">
                <icon name="info" class="h-6 w-6 inline mr-4"></icon>You do not have any point of interest added yet.
            </div>
        </div>

        <div class="form-row mt-8" v-if="pointsOfInterest.length > 0">
            <div class="form-col">
                <label for="section_title">section title</label>
                <text-input
                  id="section_title"
                  v-model="pois.title"
                />
                <span v-if="hasError('pois.title')" class="form-error">{{ showError('pois.title') }}</span>

                <label for="description" class="mt-8">description</label>
                <text-input
                  id="description"
                  v-model="pois.description"
                  multiline
                />
                <span v-if="hasError('pois.description')" class="form-error">{{ showError('pois.description') }}</span>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/mixins/Widget";
import Icon from "@/components/ui/Icon";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import ModalLink from "@/components/ui/modals/ModalLink";
import TextInput from '@/components/ui/TextInput';

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, Icon, ModalLink, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            pois:  {
                title: null,
                description: null,
            },
            isProcessing: false
        }
    },
    computed: {
        ...mapGetters({
            'pointsOfInterest': 'points_of_interest/getPointsOfInterest'
        })
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.isProcessing = true;
                this.$emit('save-page-widget', Object.assign({}, this.pois));
            }
        },
    },
    validations: {
        'pois.title' : 'required',
        'pois.description' : 'required',
    },
    created() {
        this.initValidator();

        if(this.values.length) {
            Object.assign(this.pois, {
                title: this.getValue('title'),
                description: this.getValue('description'),
            });
        }
    }
}
</script>

<style scoped>
.features label {
    display: inline;
    font-weight: 200;
    text-transform: lowercase;
}
</style>
